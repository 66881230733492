// combine all
@import 'animation';
@import 'spinner';
@import 'buttons';
@import 'scafholding';
@import 'header';
@import 'sidebar';
@import 'right-sidebar';
@import 'perfect-scrollbar';
@import 'form-pages';
@import 'table-pages';
@import 'other-common';
@import 'app';
@import 'theme-settings';