body {
  font-weight: $font-weight-normal;
}

a {
  color: $enabld-dark-blue;
  &:hover,
  &:focus {
    color: $enabld-green;
  }
}