// default colors
.skin-default-dark {

  $themecolor: #ef6d27;
  $themecolor-alt: #1f2023;
  $sidebar: #2b2b2b;
  $sidebar-alt:#1f2023;
  $sidebar-text: #717F8F;
  $sidebar-icons: #787f91;

  // Topbar
  .topbar {
    background: $quarterbac-orange;
  }
  //Sidebar
  .sidebar-nav {
    ul li a {
      &.active,
      &:hover {
        color: $white;
        i {
          color: $white;
        }
      }
      color: $gray-400;
    }
    > ul > li {
      &.active > a {
        color: $gray-400;
        border-left: 3px solid $white;
        i {
          color: $white;
        }

      }
      > a{
        i{
         color: $gray-400;
        }
      }
    }
  }

  //Bread-crumb
  .page-titles .breadcrumb .breadcrumb-item.active{
    color: $themecolor;
  }

}

