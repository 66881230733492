//bootstrap polish

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}


//bootstrap override

//navigation
.navbar-dark {
  .navbar-nav {
    .nav-link{
        color: $white;
    }
  }
}

//modal

.modal-content{
  border: none;
  @include border-radius(0);

  .modal-header{
    background: $enabld-dark-blue;
    color: $white;
    @include border-radius(0);
    .close{
      outline: none;
      color: $white;
    }

  }

}

//buttons

.btn-primary{
  background-color: $enabld-green;
  border-color: $enabld-green;
  &:focus{
      box-shadow: none;
  }
  &:hover,
  &:not(:disabled):active,
  &:not(.disabled):active{
      background-color: $enabld-dark-blue;
      border-color: $enabld-dark-blue;
  }
}

//jquery steps

.wizard-content .wizard>.steps>ul {
  >li.current .step {
    border-color: #50c837;
    color: #50c837;
  }
  >li.done .step {
    background-color: #50c837;
    border-color: #50c837;
  }

}

.wizard-content .wizard.wizard-circle>.steps>ul>li {
  &:after,
  &:before{
    background-color: #50c837;
  }
}