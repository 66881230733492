.topbar {
  .top-navbar {

    .navbar-nav > .nav-item.language-dropdown > .nav-link span.lang-name {
      font-size: 14px;

    }
  }
}

html body .navbar-dark .language-dropdown .mailbox {
  width: auto;
  min-width: 200px;
  .message-center {
    a {
      .mail-contnet {
        h5 {
          margin: 0;
          font-size: 14px;
        }
      }
    }
  }
}