// Sidebar

/*******************
use profile section
******************/

.sidebar-nav {
    .user-pro {
        background: $enabld-light-blue;
        > a {
            padding: 20px 15px;
        }
    }
    > ul > li > a {
        .badge {
            position: relative;
            top: 3px;
        }
    }
}



