/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/

@import 'topbar-mail';
@import 'Weather-widget'; 
@import 'comment';
@import 'to-do';
@import 'chat-widget';
@import 'calendar';
@import 'steamline';
@import 'feed';
@import 'carousel';
@import 'social';
@import 'profile';
@import 'common-widget';
@import 'contact';
@import 'blog';
@import 'msg-box';
@import 'activity';
@import 'news-slide';
@import 'product-review';