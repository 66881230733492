//Enabld Default

@import "bootstrap_override";
@import "libraries_override";
@import "colors/default";
@import "colors/default-dark";
@import 'colors/enabld-light-blue';

@import "core/core";
@import "icons/icons";

@import "language";


/* Enabld default style */

.bg-enabld-light-blue{
  background: $enabld-light-blue;
}

div.icheckbox_square .text-danger{
  width: max-content;
  padding-top: 26px;
}

div.ichoice-multi .text-danger{
  width: max-content;
  padding-top: 29px;
}

div.switch .text-danger{
  padding-top: 29px;
}

label.btn .text-danger{
  padding-top: 30px;
  width:200px;
}

label.btn .error{
  padding-top: 30px;
  width:200px;
}

div.btn-group-toggle {
  padding-bottom:25px;
}



table  .enabldtablelookup{
    td {
      padding: .35rem;
    }
  
}
div.enabldnestable {
  background-color: white;
  position: absolute;
  z-index: 100;
  overflow: hidden;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  cursor: pointer;
}
.dd-nodrag {
  display: block;
  height: 30px;
  margin:3px 0;
  padding: 3px 10px;

  text-decoration: none;
  font-weight: bold;
  border: 1px solid #e5e5e5;
  background: #fafafa;
  box-sizing: border-box;

}


div.enabldlookup{
  background-color: white;position: absolute;z-index: 100;overflow: hidden; border: 1px solid #e9ecef; border-radius: 4px; cursor: pointer;max-height: 150px;
}

div.enablddisplayfld{
  background-color:#e9ecef;font-size: 0.875rem;
  font-weight: 400;
  line-height: 2.5;
}
td.enablddisplayfld{
  background-color:#e9ecef;
 
}
div.enablddisplayfldlab{
  color:#343a40;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2.5;
}
td.enablddisplayfldlab{
  font-size: 12px;
  color: #343a40;
  
}
i.enalbd{
  padding: 7px;
  vertical-align: super;
}

html {overflow-y: scroll;}

.form-group {
  margin-bottom: 35px;
}

td.eRed {
  color: #fff;
  background-color: #e46a76;
}
span.eGreen {
    color: #fff;
    background-color: #00c292;
}
span.eAmber {
  color: #212529;
    background-color: #fec107;
}
span.eNo_Activity {
   color: #212529;
    background-color: #f8f9fa;
}
.box:hover {
  background: #f8f8f8;
  cursor: pointer;
}

/* Context menu */
.context-menu{
  display: none;
  z-index:90;
  position: absolute;
  border: 1px solid black;
  border-radius: 3px;
  width: 200px;
  background: white;
  box-shadow: 10px 10px 5px #888888;
}

.context-menu ul{
  list-style: none;
  padding: 2px;
}

.context-menu ul li{
  padding: 5px 2px;
  margin-bottom: 3px;
  margin-left: 10px;
  font-size: 11px;
  color: black;

  background-color: white;
}

.context-menu ul li:hover{
  cursor: pointer;
  background-color: #eceaea;
}

.ichoice-multi div{
   margin-bottom: 35px;
}
.ichoice-multi-ovr div{
  margin-bottom: 0px;
}

.hwt-content mark {
  border-radius: 3px;
  background-color: #d0bfff;
}


input.enablefilter {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4F5467;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.my-perspectiveviewer {
  height: 55vh;
}

.hover:hover {
  text-decoration: underline;
}


.pivotDiv{
  overflow: auto;
  }