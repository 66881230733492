/*******************
 Right side toggle
*******************/
.right-sidebar {
    .rpanel-title {
        background: $enabld-dark-blue;
    }
}

.default-dark-theme {
  background: $enabld-dark-blue;
  /* Old browsers */
  background: -moz-linear-gradient(left, $dark 0%, $dark 50%, $enabld-dark-blue 23%, $enabld-dark-blue 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, $dark 0%, $dark 50%, $enabld-dark-blue 23%, $enabld-dark-blue 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $dark 0%, $dark 50%, $enabld-dark-blue 23%, $enabld-dark-blue 99%);
}

.default-theme {
  background: $enabld-dark-blue;
}

.enabld-light-blue-theme {
  background: $enabld-light-blue;
}

.green-theme {
  background: $enabld-green;
}


