// App

/*============================================================== 
 For all pages 
 ============================================================== */

/*******************
 Breadcrumb and page title
*******************/

.page-titles {
    padding: 16px 10px;
    .breadcrumb {
      .breadcrumb-item.active{
          color: $enabld-dark-blue;
          font-weight: bold;
      }
  }

}
