// Green colors
.skin-enabld-light-blue {
    /*Theme Colors*/
    $themecolor: #ef6d27;
    $themecolor-alt: #717f8f;
    // Topbar
    .topbar {
        background: $themecolor;
        .top-navbar .navbar-header .navbar-brand .dark-logo {
            display: none;
        }
        .top-navbar .navbar-header .navbar-brand .light-logo {
            display: inline-block;
            color: rgba(255, 255, 255, 0.8);
        }
    }
    //Sidebar
    .sidebar-nav {
        .user-pro {
            background: $enabld-light-gray;
            > a {
                color: $enabld-light-blue;
                &:hover{
                    color: $enabld-dark-blue;
                }
            }
        }
        ul li a {
            &.active,
            &:hover {
                color: $enabld-dark-blue;
                i {
                    color: $enabld-dark-blue;
                }
            }
        }
        > ul > li {
            &.active > a {
                color: $themecolor;
                border-left: 3px solid $themecolor;
                i {
                    color: $themecolor;
                }
            }
        }
    }
    
    //Bread-crumb
    .page-titles .breadcrumb .breadcrumb-item.active{
        color: $themecolor;
    }
}