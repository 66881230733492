

/* select2 js library override */

.select2-dropdown{
  border-color: $gray-200;
}

/* sweet alert override */

body {
  .swal2-popup {
    .swal2-title {
      font-size: 28px;
      font-weight: 400;
      color: $gray-900;
    }

    #swal2-content {
      font-size: 15px;
      color: $gray-900;
      font-weight: 400;
    }

    .swal2-select {
      color: $gray-900;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
