// header part

/*******************
Notify
*******************/

.notify {
    .heartbit {
        border: 5px solid $enabld-green;
    }
    .point {
        background-color: $enabld-green;
    }
}